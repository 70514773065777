export interface TagType {
  id: string;
  text: string;
  kind: string;
  isHidden: boolean;
}

export const FOOD_TAG_ID = '0180f6bc-ad82-bfc0-ea9e-eb39d7197d95';
export const FOOD_ENV_TAG_ID = '019205b0-0201-d180-7d7f-04fdaa1ab3e5';
export const LACROSSE_TAG_ID = '018d1d79-e006-7272-cdec-24ba3959249c';
export const HBT_TAG_ID = '019001a7-dcdc-b360-f7ed-ae2a67488f27'; // "History-based treatment"
export const EPIPEN_ONLY_TAG_ID = '0191476e-db19-28b3-00eb-a7f204ec7f63';
export const CONSULTATION_NO_RESPONSE_TAG_ID = '018bcee9-d24d-6978-a235-9f6c59af18bc';
export const GETLABS_TAG_ID = '01834678-36a7-2995-7472-59d3a2b6204c';
