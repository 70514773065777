import type { AccountRoleEnum } from './AccountRoleEnum';
import type { TeamType } from './TeamType';

import type { State } from '@/services/states/types';

interface StafferType {
  id: string;
  createdAt: string;
  updatedAt: string;

  firstName: string;
  lastName: string;
  displayName: string;
  displayNameShort: string;
  displayRole: string;
  title: string;
  npi: string;

  phone: string;
  email: string;
  role: AccountRoleEnum;
  state: State;

  isAvailableAsMessagingAssignee: boolean;
  isActive: boolean;

  ehrBetaFeatures: string[];

  personalCalendlyLink: string;
  personalZoomLink: string;

  teams: Pick<TeamType, 'id'>[];
}

export { type StafferType };

export const CHET_STAFFER_ID = '0177eeaa-c3a1-f5e8-8de8-f82cad2e4df2';
