
import Draggable from '@/components/Draggable.vue';
import { showError, showMessage } from '@/components/errors';
import InsuranceTimeTracker from '@/components/InsuranceTimeTracker.vue';
import PatientCard from '@/components/PatientCard/PatientCard.vue';
import ProfileTags from '@/components/ProfileTags.vue';
import RouteAwareTab from '@/components/RouteAwareTab.vue';
import SearchableTable from '@/components/SearchableTable.vue';
import TimedCardText from '@/components/TimedCardText.vue';
import { useCalendlyLinks } from '@/composables/useCalendlyLinks';
import { useStaffersStore } from '@/stores/staffers';
import {
  AppointmentType,
  FOOD_ENV_TAG_ID,
  FOOD_TAG_ID,
  getFoodPaymentPeriodWithPrice,
  getPaymentPeriodWithPrice,
  PatientType,
  PrescriptionType,
  ProviderActionType,
  StafferType,
  TagType,
} from '@/types';
import { MessageChannelEnum } from '@/types';
import InsurancePaymentsCard from '@/views/patients/PatientDetails/Cards/InsurancePaymentsCard.vue';
import ShipmentsCard from '@/views/patients/PatientDetails/Cards/ShipmentsCard.vue';
import { UseDraggable } from '@vueuse/components';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { defineComponent } from 'vue';
import { VueEditor } from 'vue2-editor';
import AppointmentsCard from './Cards/AppointmentsCard.vue';
import CallsRecords from './Cards/CallsRecords.vue';
import AsthmaActionPlanCard from './Cards/AsthmaActionPlanCard.vue';
import ExternalResultsCard from './Cards/ExternalResultsCard.vue';
import GetLabsAppointmentsCard from './Cards/GetLabsAppointmentsCard.vue';
import InsuranceViewCard from './Cards/InsuranceCardView.vue';
import PatientSystemEventsCard from '@/views/patients/PatientDetails/Cards/PatientSystemEventsCard.vue';

import MedicalDocumentsCard from './Cards/MedicalDocumentsCard.vue';
import LabRequisitionsCard from './Cards/LabRequisitionsCard.vue';
import NotesCard from './Cards/NotesCard.vue';
import PrescribeRxCard from './Cards/PrescribeRxCard.vue';
import PrescriptionsCard from './Cards/PrescriptionsCard.vue';
import ProfileLabResultsCard from './Cards/ProfileLabResultsCard.vue';
import ProviderActionsLogCard from './Cards/ProviderActionsLogCard.vue';
import ProviderOutstandingActionsCard from './Cards/ProviderOutstandingActionsCard.vue';
import StripePaymentsCard from './Cards/StripePaymentsCard.vue';
import SubscriptionPlansCard from './Cards/SubscriptionPlansCard/SubscriptionPlansCard.vue';
import {
  CONFIRM_PATIENT_IDENTITY,
  DOWNLOAD_PDF,
  GET_PATIENT_DETAILS,
  SEND_LOGIN_LINK,
  SYNC_STRIPE_CUSTOMER,
  TRY_CREATE_SUBSCRIPTION,
  UNCONFIRM_PATIENT_IDENTITY,
  UPDATE_PATIENT_TAGS,
} from './gql';
import type { ApolloPatientData, ApolloPatientResponse, PatientDetailsData } from './types';
import { mapState } from 'pinia';
import { IMPORT_INTERCOM_DATA } from '@/views/patients/PatientDetails/gql/IMPORT_INTERCOM_DATA';
import AnsweredArtQuestionsCard from '@/views/patients/PatientDetails/Cards/AnsweredArtQuestionsCard.vue';
import { N8NWebhooksOptions, useN8NWebhooks } from '@/composables/useN8NWebhooks';
import { differenceInMonths } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useBackendSegmentEvents } from '@/composables/useBackendSegmentEvents/useBackendSegmentEvents';

import { useStorage } from '@vueuse/core';
import { VIP_TAG_ID } from '@/composables/useIsVIP';

export default defineComponent({
  components: {
    Draggable,
    AppointmentsCard,
    CallsRecords,
    AsthmaActionPlanCard,
    ExternalResultsCard,
    GetLabsAppointmentsCard,
    InsuranceTimeTracker,
    InsuranceViewCard,
    InsurancePaymentsCard,
    LabRequisitionsCard,
    MedicalDocumentsCard,
    AnsweredArtQuestionsCard,
    PatientSystemEventsCard,
    NotesCard,
    PatientCard,
    PrescriptionsCard,
    PrescribeRxCard,
    ProfileLabResultsCard,
    ProfileTags,
    ProviderActionsLogCard,
    ProviderOutstandingActionsCard,
    RouteAwareTab,
    SearchableTable,
    ShipmentsCard,
    StripePaymentsCard,
    SubscriptionPlansCard,
    TimedCardText,
    VueEditor,
    UseDraggable,
  },
  apollo: {
    patient: {
      query: GET_PATIENT_DETAILS,
      fetchPolicy: 'network-only',
      variables() {
        return { patientId: this.patientId };
      },
      update: (data: ApolloPatientData) => {
        const descCreatedAtSort = (a: Partial<{ createdAt: string }>, b: Partial<{ createdAt: string }>) =>
          moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();

        data.patient?.labresult?.sort(descCreatedAtSort);
        data.patient?.laborder?.sort(descCreatedAtSort);
        data.patient?.providerActions?.sort(descCreatedAtSort);
        data.patient?.appointments?.sort(
          (a: AppointmentType, b: AppointmentType) => moment(b.startTime).valueOf() - moment(a.startTime).valueOf()
        );

        data.patient?.shipments?.sort(
          (a: Partial<{ shippedAt: string }>, b: Partial<{ shippedAt: string }>) =>
            moment(b.shippedAt).valueOf() - moment(a.shippedAt).valueOf()
        );

        return data.patient;
      },
      result(response: ApolloPatientResponse) {
        this.patient = response.data?.patient || null;
      },
    },
  },
  metaInfo() {
    return {
      title: this.patient ? `${this.patient.firstName} ${this.patient.lastName} - Curex` : 'Curex Provider Portal',
      htmlAttrs: {
        lang: 'en',
      },
    };
  },
  data(): PatientDetailsData & {
    currentStaffer?: StafferType;
    currentStafferBetaFeatures?: string[];
    showIdCard: boolean;
    showUnverifyIdCard: boolean;
    showVerifyIdCard: boolean;
  } {
    return {
      debouncedUpdatePatientTags: null,
      loading: 0,
      patient: null,
      wrapperWidth: 0,
      debouncedSetNewWrapperWidth: null,
      sidebarLeftVisible: false,
      showIdCard: false,
      showUnverifyIdCard: false,
      showVerifyIdCard: false,
    };
  },
  computed: {
    ...mapState(useStaffersStore, ['currentStaffer', 'currentStafferBetaFeatures']),
    patientId(): string {
      return (this.$route.query?.['patient-id'] as string) || this.$route?.params?.pk || '';
    },
    userId(): string {
      return this?.patient?.userId;
    },
    hasFoodAllergies(): boolean {
      // check if patient has "Food" or "Food+Env" tag
      return !!this?.patient?.tags?.find((tag: TagType) => tag?.id === FOOD_TAG_ID || tag?.id === FOOD_ENV_TAG_ID);
    },
    requestedSubscriptionPlanDescription(): string {
      return this.hasFoodAllergies
        ? getFoodPaymentPeriodWithPrice(this.patient)
        : getPaymentPeriodWithPrice(this.patient);
    },
    moreColumns(): boolean {
      return !this.$route?.query?.['more-columns']?.length || this.$route?.query?.['more-columns'] === 'yes';
    },
    columnsWidths(): number[] {
      return useStorage('cxPatientDetailsColumns', [33, 33, 34]).value;
    },
    n8n() {
      if (!this.userId) {
        return;
      }

      return useN8NWebhooks(this.userId);
    },
    backendEventEmitter() {
      return useBackendSegmentEvents();
    },
    months(): number {
      if (!this.patient) {
        return -1;
      }

      const firstAcSlitPrescription = (this.patient.prescriptionSet as PrescriptionType[]).findLast(
        ({ signedAt }) => !!signedAt
      );

      return firstAcSlitPrescription ? differenceInMonths(new Date(), toDate(firstAcSlitPrescription.signedAt)) : 0;
    },
    isScarletOrderFeatureEnabled(): boolean {
      return !!this.currentStafferBetaFeatures?.['scarlet-order'];
    },
    hasInsurancePayments(): boolean {
      return this.patient && this.patient.insurancePayments && !!this.patient.insurancePayments.length;
    },
    outstandingProviderActions(): Array<ProviderActionType> {
      if (!this.patient || !this.patient.providerActions) {
        return [];
      }

      return this.patient!.providerActions!.filter((action: ProviderActionType) => action.status === 'pending');
    },
    providerActionsLog(): Array<ProviderActionType> {
      if (!this.patient || !this.patient.providerActions) {
        return [];
      }

      return this.patient!.providerActions!.filter((action: ProviderActionType) => action.status !== 'pending');
    },
    calendlyAppointments(): AppointmentType[] {
      if (!this.patient || !this.patient.appointments) return [];
      return this.patient.appointments.filter(
        (appointment: AppointmentType) =>
          (!!appointment.calendlyId || !appointment.notes) && appointment.kind != 'getlabs_visit'
      );
    },
    getLabsAppointments(): AppointmentType[] {
      if (!this.patient || !this.patient.appointments) return [];
      return this.patient.appointments.filter((appointment: AppointmentType) => appointment.kind == 'getlabs_visit');
    },
    objectId(): string | undefined {
      return this.$route.query?.objectId as string;
    },
    sidebar(): string | undefined {
      return this.$route.query?.sidebar as string;
    },
    windowsAvailable(): boolean {
      return !!this?.currentStafferBetaFeatures?.windows;
    },
    windows(): string[] {
      return [(this.$route.query?.windows as string[]) || []].flat();
    },
    windowDefinitions(): any[] {
      if (!this.patient || !this.windows) {
        return [];
      }

      return this.windows.map((window) => this.getWindowDefinition(window)).filter(Boolean);
    },
    sidebarVisible(): boolean {
      return !!this.patient && !!this.sidebar;
    },
    objectToEdit(): any | undefined {
      if (!(this.sidebarVisible && this.objectId)) {
        return;
      }

      switch (this.sidebar) {
        case 'notes-form':
          return this.patient!.notes!.find((item) => item.id === this.$route.query.objectId);
        case 'action-form':
          return this.patient!.providerActions!.find((item) => item.id === this.$route.query.objectId);
        case 'external-lab-requisition-form':
          return this.patient!.externalLabOrders!.find((item) => item.id === this.$route.query.objectId);
        case 'insurance-card-form':
          return this.patient!.insuranceCards!.find((item) => item.id === this.$route.query.objectId);
        default:
          console.warn('unknown "sidebar" query param in the URL', this.$route.query.sidebar);
          return { id: this.objectId } as any;
      }
    },
    formToDisplay() {
      if (!this.patient) {
        return null;
      }

      switch (this.sidebar) {
        case 'initiate-sms':
          return {
            binds: { patientName: this.patient?.firstName },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/InitiateSmsForm.vue'),
          };
        case 'action-form':
        case 'action-creation-form':
          return {
            binds: { patient: this.patient, action: this.objectToEdit },
            component: () =>
              import(
                '@/views/patients/PatientDetails/SidebarForms/CompactProviderActionForm/CompactProviderActionForm.vue'
              ),
            skipClose: this.sidebar === 'action-form',
          };
        case 'notes-form':
          return {
            binds: { note: this.objectToEdit, patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/NotesForm.vue'),
          };
        case 'external-lab-result-form':
          return {
            binds: { externalLabResult: this.objectToEdit },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/ExternalLabResultForm.vue'),
          };
        case 'upload-medical-documents-form':
          return {
            binds: { patientId: this.patient?.id || '', clientId: this.patient?.userId || '' },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/UploadMedicalDocumentsForm.vue'),
          };
        case 'asthma-action-plan-form':
          return {
            binds: { asthmaActionPlan: this.objectToEdit },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/AsthmaActionPlanForm.vue'),
          };
        case 'merge-profiles-form':
          return {
            binds: { patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/MergeProfilesForm.vue'),
          };
        case 'move-patient-form':
          return {
            binds: { patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/MovePatientForm.vue'),
          };
        case 'patient-info-form':
          return {
            binds: { patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/PatientInfoForm.vue'),
          };
        case 'external-lab-requisition-form':
          return {
            binds: { externalLabRequisition: this.objectToEdit, patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/ExternalLabRequisitionForm.vue'),
          };
        case 'insurance-card-form':
          return {
            binds: { insuranceCardId: this.objectId, patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/InsuranceCardForm.vue'),
          };
        case 'epipen-select-form':
          return {
            binds: { patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/EpiPenForm.vue'),
          };
        case 'next-shipment-date-form':
          return {
            binds: { patient: this.patient },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/NextShipmentDateForm.vue'),
          };
        case 'preclinical-sms':
          return {
            binds: {
              channels: [MessageChannelEnum.SMS],
              closeOnSuccess: true,
              contentTemplate:
                'Hi {firstName}.\n\nThis is Sam from Curex. We just got your test results! Please fill this pre-clinical questionnaire ASAP. After this we will connect you with your clinician to discuss your treatment.\nhttps://getcurex.com/demographics/preclinical',
              openedBy: '',
              patient: this.patient,
              subjectTemplate: '',
              templateVariables: {
                firstName: this.patient?.firstName,
                currentUserFirstName: `${this.currentStaffer.firstName}`,
              },
            },
            component: () => import('@/views/tracker/sidebar-forms/SendMessageForm.vue'),
          };
        case 'meeting-reminder-message': {
          const staffer = this?.$route?.query?.stafferId
            ? useStaffersStore().staffers.find(({ id }) => id === this.$route.query.stafferId)
            : useStaffersStore().currentStaffer;
          const calendlyLinks = useCalendlyLinks(this.patient, { staffer });
          const calendlyLinksPatientPhysician = staffer ? calendlyLinks : useCalendlyLinks(this.patient);

          return {
            binds: {
              channels: [MessageChannelEnum.SMS, MessageChannelEnum.EMAIL],
              closeOnSuccess: true,
              contentTemplate:
                'Hi!\n\nThis is {currentUserFirstName} from Curex. Are you still able to join our call today? If so here is the link to our meeting:\n{providerZoomLink}\n\nIf something came up, I completely understand. You can reschedule using the link below:\n{rescheduleLink}',
              openedBy: '',
              patient: this.patient,
              subjectTemplate: 'Curex - Reminder',
              templateVariables: {
                currentUserFirstName: `${staffer?.firstName || this.currentStaffer.firstName}`,
                providerZoomLink: staffer?.personalZoomLink || this.patient?.physician?.personalZoomLink,
                rescheduleLink: calendlyLinksPatientPhysician[this.objectId as keyof typeof calendlyLinks],
              },
            },
            component: () => import('@/views/tracker/sidebar-forms/SendMessageForm.vue'),
          };
        }
        case 'drops-prescription-form-ac':
          return {
            binds: {
              patient: this.patient,
              prescriptionId: this.objectId,
            },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/SidebarFormDropsPrescriptionAC.vue'),
          };
        case 'manage-lab-appointment':
          return {
            binds: {
              patient: this.patient,
            },
            component: () => import('@/views/patients/PatientDetails/SidebarForms/FormManageLabAppointment.vue'),
          };
        default:
          return null;
      }
    },
    isLargeScreen(): boolean {
      return !this.wrapperWidth ? true : this.wrapperWidth > 900;
    },
  },
  created(): void {
    this.debouncedUpdatePatientTags = debounce(this.updatePatientTags, 1000);
  },
  mounted() {
    this.debouncedSetNewWrapperWidth = debounce(this.setNewWrapperWidth, 300);

    try {
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.debouncedSetNewWrapperWidth?.(entry?.contentRect?.width);
        });
      }).observe(this?.$refs?.patientDetailsElement as HTMLDivElement);
    } catch (e) {
      console.error('ResizeObserver', e);
    }
  },
  methods: {
    setColumnsWidths(newValues: number[]): void {
      useStorage('cxPatientDetailsColumns', [33, 33, 34]).value = newValues;
    },
    setNewWrapperWidth(newWidth: number = 0) {
      this.wrapperWidth = newWidth;
    },
    showHideIframe(): void {
      const { iframe, ...query } = this.$route.query;
      if (!iframe?.length || iframe === 'false') {
        this.$router.push({ query: { ...this.$route.query, iframe: 'true' } });
      } else {
        this.$router.replace({ query });
      }
    },
    async openSidebar(formName: string, objectToEdit?: { id: string; [key: string]: any }): Promise<void> {
      if (this.windowsAvailable) {
        console.log('opening sidebar', formName, 'with object', objectToEdit);

        /**
         * To close sidebar on router change remove no-close-on-route-change prop from sidebar
         * @doc https://bootstrap-vue.org/docs/components/sidebar#closing-on-route-change
         */
        const windowName = objectToEdit?.id ? `${formName}$${objectToEdit.id}` : formName;
        if (!this.windows.includes(windowName)) {
          const query = { ...this.$route.query, windows: [...this.windows, windowName] };

          await this.$router.replace({ query });
        }
      } else {
        console.log('opening sidebar', formName, 'with object', objectToEdit);

        if (this.sidebarVisible) {
          return;
        }

        /**
         * To close sidebar on router change remove no-close-on-route-change prop from sidebar
         * @doc https://bootstrap-vue.org/docs/components/sidebar#closing-on-route-change
         */
        if (formName !== this.sidebar || objectToEdit?.id !== this.objectId) {
          const query = {
            ...this.$route.query,
            sidebar: formName,
            objectId: objectToEdit?.id,
            stafferId: objectToEdit?.stafferId,
          };

          await this.$router.replace({ query });
        }
      }
    },
    closeSidebarNoReload(windowDefinition: Record<string, any>): void {
      if (this.windowsAvailable) {
        // eslint-disable-next-line
        const query = { ...this.$route.query };
        const windows = this.windows.filter((window: string) => window !== windowDefinition.key);

        this.$router.replace({ query: { ...query, windows: windows.length ? windows : undefined } });
      } else {
        // eslint-disable-next-line
        const query = { ...this.$route.query };

        delete query.sidebar;
        delete query.objectId;
        delete query.stafferId;

        this.$router.replace({ query });
      }
    },
    closeSidebar(windowDefinition: Record<string, any>): void {
      if (this.windowsAvailable) {
        if (!windowDefinition.skipClose) {
          this.closeSidebarNoReload(windowDefinition);
        }

        this.$apollo.queries.patient.refetch();
      } else {
        if (!windowDefinition) {
          this.closeSidebarNoReload();
        }

        this.$apollo.queries.patient.refetch();
      }
    },
    async updatePatientTags(patientTagIds: string[]): Promise<void> {
      const data = {
        patientId: this.patientId,
        tagIds: patientTagIds,
      };

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PATIENT_TAGS,
          variables: data,
        });

        // Use XOR to refresh only if VIP tag add or remove
        if (this.patient.tags.some(({ id }) => id === VIP_TAG_ID) ^ patientTagIds.includes(VIP_TAG_ID)) {
          await this.refreshPatientModel();
        }
      } catch (error: any) {
        showError(this as any, error.message);

        // Refresh because UI has been updated before mutation
        await this.refreshPatientModel();
      }
    },
    async confirmPatientIdentity(): Promise<void> {
      const data = {
        id: this.patientId,
      };

      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: CONFIRM_PATIENT_IDENTITY,
          variables: data,
        });

        await this.$apollo.queries.patient.refetch();
      } catch (error: any) {
        showError(this as any, error.message);
      } finally {
        this.loading--;
        this.showUnverifyIdCard = false;
        this.showVerifyIdCard = false;
      }
    },
    async unconfirmPatientIdentity(): Promise<void> {
      const data = {
        id: this.patientId,
      };

      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: UNCONFIRM_PATIENT_IDENTITY,
          variables: data,
        });

        await this.$apollo.queries.patient.refetch();
      } catch (error: any) {
        showError(this as any, error.message);
      } finally {
        this.loading--;
        this.showUnverifyIdCard = false;
        this.showVerifyIdCard = false;
      }
    },
    async sendLoginLink(): Promise<void> {
      const data = {
        id: this.patient.user.id,
      };

      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: SEND_LOGIN_LINK,
          variables: data,
        });
        showMessage(this as any, 'Login link sent by email and sms');
      } catch (error: any) {
        showError(this as any, error.message);
      } finally {
        this.loading--;
      }
    },
    async syncStripeCustomer(): Promise<void> {
      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: SYNC_STRIPE_CUSTOMER,
          variables: {
            patientId: this.patientId,
          },
        });
        showMessage(this as any, 'The Stripe customer data was successfully synced to the connected account.');
      } catch (error: any) {
        showError(this as any, error.message);
      }
      this.loading--;
    },
    async importIntercomData(): Promise<void> {
      this.loading++;
      try {
        const response = await this.$apollo.mutate({
          mutation: IMPORT_INTERCOM_DATA,
          variables: {
            patientId: this.patientId,
          },
        });
        showMessage(
          this as any,
          `Imported ${response.data.importMessagesFromIntercom.importedConversationsCount} conversations.`
        );
      } catch (error: any) {
        showError(this as any, error.message);
      }
      this.loading--;
    },
    async tryCreateSubscription(onlyActions: boolean): Promise<void> {
      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: TRY_CREATE_SUBSCRIPTION,
          variables: {
            patientId: this.patientId,
            onlyActions: onlyActions,
          },
        });
        showMessage(this as any, 'The request was sent.');
      } catch (error: any) {
        showError(this as any, error.message);
      }
      this.loading--;
    },

    async generatePdfDownloadLink() {
      const forceRegenerate = false;
      this.loading++;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: DOWNLOAD_PDF,
          variables: {
            chatId: this.patientId,
            forceRegenerate: forceRegenerate,
            forceTemplate: '',
          },
        });

        this.pdfLink = data.generateChatPdfDownloadLink.s3Url;
        this.downloadPdf(this.pdfLink);
        showMessage(this as any, 'Download started');
      } catch (error: any) {
        this.error = error.message;
        showError(this as any, error.message);
      } finally {
        this.loading--;
      }
    },

    downloadPdf(url: string) {
      console.log('Download_function');
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'generated.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    openInAdminSite(): void {
      this.openNewPage(this.patient.adminSiteUrl);
    },
    redirectToScarletOrderView(scarletOrderId?: string): void {
      this.openNewPage(
        this.$router.resolve({
          name: 'scarletOrder',
          params: { patientId: this.patient.id, scarletOrderId },
          query: this.$route.query,
        }).href
      );
    },
    openNewPage(url: string): void {
      window.open(url, '_blank');
    },
    showPopover(popoverId: string): void {
      if (!popoverId) {
        return;
      }

      this.$root.$emit('bv::show::popover', popoverId);
    },
    hidePopover(popoverId: string): void {
      if (!popoverId) {
        return;
      }

      this.$root.$emit('bv::hide::popover', popoverId);
    },
    refreshPatientModel(): void {
      this.$apollo.queries.patient.refetch();
    },
    getObjectToEdit(windowName: string, objectId: string) {
      if (!objectId) {
        return null;
      }

      switch (windowName) {
        case 'notes-form':
          return this.patient!.notes!.find((item: { id: string }) => item.id === objectId);
        case 'action-form':
          return this.patient!.providerActions!.find((item: { id: string }) => item.id === objectId);
        case 'external-lab-requisition-form':
          return this.patient!.externalLabOrders!.find((item: { id: string }) => item.id === objectId);
        case 'insurance-card-form':
          return this.patient!.insuranceCards!.find((item: { id: string }) => item.id === objectId);
        default:
          console.warn('unknown "sidebar" query param in the URL', window);
          return objectId;
      }
    },
    isGetLabsAppointmentsExists(patient: PatientType) {
      if (!patient || !patient.appointments) return false;

      const appointments = patient.appointments.filter(
        (appointment: AppointmentType) => appointment.kind == 'getlabs_visit'
      );

      if (appointments.length > 0) {
        return true;
      }
      return false;
    },
    getWindowDefinition(window: string) {
      const [name, objectId] = window.split('$');
      const objectToEdit = this.getObjectToEdit(name, objectId);
      console.log('getWindowDefinition', { window, name, objectId, objectToEdit });

      const common = { key: window, name, storageType: objectId ? 'session' : 'local' };

      if (name === 'drops-prescription-form-ac') {
        return {
          binds: {
            patient: this.patient,
            prescriptionId: objectId,
          },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/SidebarFormDropsPrescriptionAC.vue'),
          ...common,
        };
      }
      if (name === 'initiate-sms') {
        return {
          binds: { patientName: this.patient?.firstName },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/InitiateSmsForm.vue'),
          ...common,
        };
      }
      if (name === 'action-creation-form' || name === 'action-form') {
        return {
          binds: { patient: this.patient, action: objectToEdit },
          component: () =>
            import(
              '@/views/patients/PatientDetails/SidebarForms/CompactProviderActionForm/CompactProviderActionForm.vue'
            ),
          skipClose: name === 'action-form',
          ...common,
        };
      }
      if (name === 'notes-form') {
        return {
          binds: { note: objectToEdit },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/NotesForm.vue'),
          ...common,
        };
      }
      if (name === 'external-lab-result-form') {
        return {
          binds: { externalLabResult: objectToEdit },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/ExternalLabResultForm.vue'),
          ...common,
        };
      }
      if (name === 'asthma-action-plan-form') {
        return {
          binds: { asthmaActionPlan: objectToEdit },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/AsthmaActionPlanForm.vue'),
          ...common,
        };
      }
      if (name === 'merge-profiles-form') {
        return {
          binds: { patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/MergeProfilesForm.vue'),
          ...common,
        };
      }
      if (name === 'move-patient-form') {
        return {
          binds: { patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/MovePatientForm.vue'),
          ...common,
        };
      }
      if (name === 'patient-info-form') {
        return {
          binds: { patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/PatientInfoForm.vue'),
          ...common,
        };
      }
      if (name === 'external-lab-requisition-form') {
        return {
          binds: { externalLabRequisition: objectToEdit, patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/ExternalLabRequisitionForm.vue'),
          ...common,
        };
      }
      if (name === 'insurance-card-form') {
        return {
          binds: { insuranceCardId: objectId, patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/InsuranceCardForm.vue'),
          ...common,
        };
      }
      if (name === 'epipen-select-form') {
        return {
          binds: { patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/EpiPenForm.vue'),
          ...common,
        };
      }
      if (name === 'next-shipment-date-form') {
        return {
          binds: { patient: this.patient },
          component: () => import('@/views/patients/PatientDetails/SidebarForms/NextShipmentDateForm.vue'),
          ...common,
        };
      }
      if (name === 'preclinical-sms') {
        return {
          binds: {
            channels: [MessageChannelEnum.SMS],
            closeOnSuccess: true,
            contentTemplate:
              'Hi {firstName}.\n\nThis is {currentUserFirstName} from Curex. We just got your test results! Please fill this pre-clinical questionnaire ASAP. After this we will connect you with your clinician to discuss your treatment.\nhttps://getcurex.com/demographics/preclinical',
            openedBy: '',
            patient: this.patient,
            subjectTemplate: '',
            templateVariables: {
              firstName: this.patient?.firstName,
              currentUserFirstName: `${this.currentStaffer.firstName}`,
            },
          },
          component: () => import('@/views/tracker/sidebar-forms/SendMessageForm.vue'),
          ...common,
        };
      }
      if (name === 'meeting-reminder-message') {
        const staffer = this?.$route?.query?.stafferId
          ? useStaffersStore().staffers.find(({ id }) => id === this.$route.query.stafferId)
          : useStaffersStore().currentStaffer;
        const calendlyLinks = useCalendlyLinks(this.patient, { staffer });
        const calendlyLinksPatientPhysician = staffer ? calendlyLinks : useCalendlyLinks(this.patient);

        return {
          binds: {
            channels: [MessageChannelEnum.SMS, MessageChannelEnum.EMAIL],
            closeOnSuccess: true,
            contentTemplate:
              'Hi!\n\nThis is {currentUserFirstName} from Curex. Are you still able to join our call today? If so here is the link to our meeting:\n{providerZoomLink}\n\nIf something came up, I completely understand. You can reschedule using the link below:\n{rescheduleLink}',
            openedBy: '',
            patient: this.patient,
            subjectTemplate: 'Curex - Reminder',
            templateVariables: {
              currentUserFirstName: `${staffer?.firstName || this.currentStaffer.firstName}`,
              providerZoomLink: staffer?.personalZoomLink || this.patient?.physician?.personalZoomLink,
              rescheduleLink: calendlyLinksPatientPhysician[this.objectId as keyof typeof calendlyLinks],
            },
          },
          component: () => import('@/views/tracker/sidebar-forms/SendMessageForm.vue'),
          ...common,
        };
      }

      return null;
    },
    async callN8NWorkflow(workflowName: string, options?: N8NWebhooksOptions): Promise<void> {
      if (!this.n8n) {
        return;
      }

      await this.n8n[workflowName](options);
    },
    async emitVisitSurveyIntakeStarted(): Promise<void> {
      if (!this.userId) {
        return;
      }

      await this.backendEventEmitter.emitVisitSurveyIntakeStarted({ userId: this.userId, patientId: this.patient?.id });
    },
  },
});
